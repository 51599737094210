import React,{ } from "react"
import { navigate } from "gatsby"

const StripeCancelPage = ({ location, data, path }) =>{
  if (typeof window !== `undefined`) {
    navigate("/");
  }
  return(
    <>
    トップページに戻ります
    </>
  )
}
export default StripeCancelPage